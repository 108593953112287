<!--我的周报-->
<template lang="html">
    <div class="commonWrap">
        <div class="weekBox">
            <el-page-header v-if="isEmpty == 2" @back="goBack" :content="title" style="margin:10px 0 20px"></el-page-header>
            <el-collapse v-if="isEmpty == 2" v-model="selectWeek">
                <el-card style="margin-bottom:10px">
                    <el-collapse-item name="1">
                        <template slot="title">
                            <el-row type="flex" justify="space-between" align="middle" style="width:100%;padding-right:10px">
                                <div v-if="$route.query.type == 2" class="title">第{{formData.week_num}}周</div>
                                <div v-if="$route.query.type == 1" class="title">{{formData.month}}月</div>
                            </el-row>
                        </template>

                        <el-row >
                            <!--本周总结-->
                            <el-row class="tipBox">
                                <b style="background:#e5f3f3;color:#515a6e">{{$route.query.type == 2 ? '本周总结' : '本月总结'}}：{{formData.begin_time}} - {{formData.end_time}}</b>
                            </el-row>
                            <el-table :data="formData.done" ref="singleTable" highlight-current-row border :cell-class-name="cellStyle"  :row-class-name="tableRowAddIndex" :header-cell-style="{background:'#f5f5f5',color:'#515A6E'}" :span-method="formData.doneColspan">
                                <el-table-column label="序号" width="80" align="center">
                                    <template slot-scope="scope">
                                        <div>{{scope.$index+1}}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="class_name" label="工作类别"></el-table-column>
                                <el-table-column prop="content" label="工作计划"></el-table-column>
                                <el-table-column prop="plan_real" label="预计完成" ></el-table-column>
                                <el-table-column prop="done_real" label="实际完成"></el-table-column>
                                <el-table-column prop="remark" label="完成情况说明" width="180"></el-table-column>
								<el-table-column prop="plan_result" label="结果定义"></el-table-column>
								<!-- <el-table-column prop="up_mark" label="点评">
                                    <template slot-scope="scope">
                                        <el-rate :disabled="true" color="#008685" v-model="scope.row.up_mark"></el-rate>
                                    </template>
                                </el-table-column> -->
                            </el-table>

                            <!--下周计划-->
                            <el-row class="tipBox" style="margin-top:40px">
                                <b>{{$route.query.type == 2 ? '下周计划' : '下月计划'}}：{{formData.plan_begin_time}} - {{formData.plan_end_time}}</b>
                            </el-row>

                            <el-table :data="formData.plan" border style="width: 100%" highlight-current-row :cell-class-name="cellStyle" :row-class-name="tableRowAddIndex" :header-cell-style="{background:'rgba(0,134,133,0.1)',borderColor:'#e5e5e5',color:'#515A6E'}" :span-method="formData.planColspan">
                                <el-table-column label="序号" width="80" align="center">
                                    <template slot-scope="scope">
                                        <div>{{scope.$index+1}}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="class_name" label="工作类别" width="250"></el-table-column>
                                <el-table-column prop="content" label="工作计划"></el-table-column>
                                <el-table-column prop="plan_result" label="结果定义"></el-table-column>
                                <el-table-column prop="plan_real" label="预计完成" width="250"></el-table-column>
                            </el-table>
                        </el-row>
                    </el-collapse-item>
                </el-card>
            </el-collapse>

            <el-empty v-if="isEmpty == 1" description="暂无数据"></el-empty>

            <!--留言抽屉-->
            <drawerMessage ref="drawerMessage" ></drawerMessage>
            <!--月报抽屉-->
            <drawerMonth ref="drawerMonth" ></drawerMonth>
        </div>
    </div>
</template>

<script>
import drawerMessage from "@/components/drawerMessage.vue"; //留言抽屉
import drawerMonth from "@/components/drawerMonth.vue"; //月报抽屉

export default {
    components: {
        drawerMessage,
        drawerMonth
    },
    data() {
        return {
            formData: {},
            selectWeek: ['1'], //打开id

            count: 2,
            isEmpty: '',
            title: ''
        }
    },


    watch: {
        $route() {
            this.init();
        }
    },

    mounted() {
        this.init();
    },

    methods: {
        init: function() {
            this.title = this.$route.query.name + '的工作汇报'
            this.getList();
        },

        //概况详情
        getList: function() {
            this.http.post('/admin.survey/showList', {
                uid: this.$route.query.id,
                type: this.$route.query.type == 1 ? 'month' : 'week',
                ctime: this.$route.query.ctime
            }).then(response => {
                this.handleColspan(response);
                this.formData = response;
                this.isEmpty = !response ? 1 : 2;
            })
        },

        // 表格工作类别合并的方法
        handleColspan(week) {
            if (week.done_class_merge == 1) {
                week.doneColspan = function({
                    row,
                    column,
                    rowIndex,
                    columnIndex
                }) {
                    let spanArr = [] // 页面展示的数据，不一定是全部的数据，所以每次都清空之前存储的 保证遍历的数据是最新的数据。以免造成数据渲染混乱
                    let pos = 0
                    let data = week.done
                    //遍历数据
                    data.forEach((item, index) => {
                        if (index == 0) { //判断是否是第一项（取决于你准备合并表格第几项）
                            spanArr.push(1)
                            pos = 0
                        } else { //不是第一项时，就根据标识去存储
                            if (data[index].class_name === data[index - 1].class_name) { // 查找到符合条件的数据时每次要把之前存储的数据+1
                                spanArr[pos] += 1
                                spanArr.push(0)
                            } else { // 没有符合的数据时，要记住当前的index
                                spanArr.push(1)
                                pos = index
                            }
                        }
                    })
                    if (columnIndex === 1) {
                        const _row = spanArr[rowIndex]
                        const _col = _row > 0 ? 1 : 0
                        return {
                            rowspan: _row,
                            colspan: _col
                        }
                    } else {
                        return false
                    }
                }
            }
            if (week.plan_class_merge == 1) {
                week.planColspan = function({
                    row,
                    column,
                    rowIndex,
                    columnIndex
                }) {
                    let spanArr = [] // 页面展示的数据，不一定是全部的数据，所以每次都清空之前存储的 保证遍历的数据是最新的数据。以免造成数据渲染混乱
                    let pos = 0
                    let data = week.plan
                    //遍历数据
                    data.forEach((item, index) => {
                        if (index == 0) { //判断是否是第一项（取决于你准备合并表格第几项）
                            spanArr.push(1)
                            pos = 0
                        } else { //不是第一项时，就根据标识去存储
                            if (data[index].class_name === data[index - 1].class_name) { // 查找到符合条件的数据时每次要把之前存储的数据+1
                                spanArr[pos] += 1
                                spanArr.push(0)
                            } else { // 没有符合的数据时，要记住当前的index
                                spanArr.push(1)
                                pos = index
                            }
                        }
                    })
                    if (columnIndex === 1) {
                        const _row = spanArr[rowIndex]
                        const _col = _row > 0 ? 1 : 0
                        return {
                            rowspan: _row,
                            colspan: _col
                        }
                    } else {
                        return false
                    }
                }
            }
        },

        tableRowAddIndex: function({
            row,
            rowIndex
        }) {
            row.index = rowIndex;
        },

        //设置优先级序号添加样式
        cellStyle: function({
            row,
            column,
            rowIndex,
            columnIndex
        }) {
            //优先级高亮
            if (row.priority == 2) {
                if (columnIndex == 0) {
                    return 'active'
                }
            }
            //上级添加计划
            if (row.add_status == 2) {
                return 'topPlanActive'
            }
        },



        //点击留言
        messClick: function(param) {
            //显示留言抽屉
            this.$refs.drawerMessage.open();
            //传递参数
            this.$refs.drawerMessage.formData = param;
            //调取留言数据
            this.$refs.drawerMessage.getMessage(false);
        },

        //点击查看月报
        openMonth: function() {
            //显示留言抽屉
            this.$refs.drawerMonth.open();
            //调取留言数据
            this.$refs.drawerMonth.getMonth();
        },
        goBack: function() {
            this.$router.go(-1);
        }
    }
}
</script>
<style media="screen">
.el-table .active {
    width: 3px;
    height: 100%;
    background: #e5f3f3;
}

.selectActive {
    background: #e5f3f3;
}

.topPlanActive {
    color: rgba(179, 0, 4, 1);
}
</style>
