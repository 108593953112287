<!--留言抽屉-->
<template lang="html">
    <el-drawer id="drawer" title="" :withHeader="false" :visible.sync="drawerVisible" :append-to-body="true" :modal="false" :modal-append-to-body="false" size="40%" style="margin-top:50px">
        <!--top-->
        <div class="drawerTop flex flex-ai-c flex-jc-sb">
             <div class="headBox  flex flex-ai-c">
                 <el-image :src="require('@/assets/ly.png')" fit="cover"></el-image>
                 <span>留言</span>
             </div>
             <i class="el-icon-close" @click="close"></i>
        </div>
        <!--留言输入框-->
        <div class="drawerMess_cont">
            <div class="messTextarea">
                <el-input v-model="content" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入内容" resize="none" ></el-input>
                <div class="messSubmit flex flex-ai-c flex-jc-fe">
                    <el-button type="primary" size="mini" @click="saveMessage()">发送</el-button>
                </div>
            </div>
            <div class="messTextarea mess_content ">
                <div class="flex flex-column messMain" v-for="(item,index) in messArray" :key="index">
                    <div class=" nickname flex flex-jc-sb flex-ai-c">
                        <div class="flex flex-ai-c">
                            <el-image :src="require('@/assets/avatars.jpg')" fit="cover"></el-image>
                            <span>{{$root.userInfo.realname}}</span>
                        </div>
                        <!-- <div>
                            <i class="el-icon-edit" style="margin-right:10px" @click="editMess(item)"></i>
                            <i class="el-icon-delete" @click="delMes(item)"></i>
                        </div> -->
                    </div>
                    <div class="content">{{item.content}}</div>
                    <div class="time">创建时间：{{item.create_time}}</div>
                </div>
                <el-empty v-if='isEmpty'  description="暂无数据"></el-empty>
            </div>



        </div>
    </el-drawer>
</template>

<script>
export default {
    data() {
        return {
            messArray:[],//留言列表
            formData:{},

            content:'',
            messId:'',

            isEmpty:false,//留言数据是否存在
            drawerVisible: false, //抽屉开关
        }
    },
    mounted() {
        // console.log(this.formData)
    },
    methods: {
        //留言数据
        getMessage: function(){
            this.messArray = [];
            this.http.post('/admin.note/index',{
                week_id:this.formData.id,
                user_id:this.$root.userInfo.id
            }).then(response=>{
                this.messArray = response.list;
                //判断是否有数据
                this.isEmpty = this.messArray.length == 0 ? true : false;
            })
        },

        //编辑留言
        editMess: function(param){
            this.content = param.content;
            this.messId = param.id
        },
        //保存留言
        saveMessage: function(){
            if(!this.content) return this.$message.error('请填写留言内容');
            this.http.post('/admin.note/save',{
                week_id:this.formData.id,
                content:this.content,
                id:this.messId,
                user_id:this.$root.userInfo.id
            },false).then(()=>{
                this.content = '';
                this.getMessage();
                this.$parent.getList();
            })
        },

        //删除留言
        delMes: function(item){
            this.$confirm(`确定删除吗?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
                this.http.post('/admin.note/delete',{
                    id:item.id
                }).then(()=>{
                    this.$message.success('删除成功');
                    this.getMessage();
                    this.$parent.getList();
                })
			})
        },
        open: function(){
            this.content = '';
            this.drawerVisible = true;
        },
        close: function(){
            this.drawerVisible = false;
        },

    }
}
</script>
