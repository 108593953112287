<!--查看本月月报-->
<template lang="html">
    <el-drawer id="drawer" title="" :withHeader="false" :visible.sync="drawerVisible" :append-to-body="true" :modal="false" :modal-append-to-body="false" size="50%" style="margin-top:50px">
        <!--top-->
        <div class="drawerTop flex flex-ai-c flex-jc-sb">
             <div class="headBox  flex flex-ai-c">
                 <el-image :src="require('@/assets/ly.png')" fit="cover"></el-image>
                 <span>月报详情</span>
             </div>
             <i class="el-icon-close" @click="close"></i>
        </div>

        <!--月报计划-->
        <div class="drawerMonth">
            <div v-for="item in monthArray">
                <el-row class="tipBox">
                    <b style="background:#e5f3f3;color:#515a6e">{{item.month_start}} - {{item.month_end}}</b>
                </el-row>
                <el-table :data="item.plan" highlight-current-row>
                    <el-table-column label="序号" width="80">
                        <template slot-scope="scope">
                            <div>{{scope.$index+1}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="class_name" label="工作类别" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="content" label="工作计划"></el-table-column>
                    <el-table-column prop="plan_real" label="预计完成" show-overflow-tooltip width="120"></el-table-column>            
                </el-table>
            </div>
            <el-empty v-if="isWeek" description="暂无数据"></el-empty>
        </div>
    </el-drawer>
</template>

<script>
export default {
    data() {
        return {
            monthArray:[],//留言列表

            userId:'',
            begin_time:'',

            drawerVisible: false, //抽屉开关
            isWeek:false
        }
    },

    methods: {
        //当月月报数据
        getMonth: function(){
           
            console.log(this);
            this.monthArray = [];
            this.http.post('/admin.work/monthList',{
                uid:this.userId,
                date:this.begin_time
            },false).then(response=>{
                this.monthArray = response;
                this.isWeek = this.monthArray.length == 0 ? true : false;
            })
        },

        open: function(){
            this.content = '';
            this.drawerVisible = true;
        },
        close: function(){
            this.drawerVisible = false;
        }
    }
}
</script>
